.FlexButton-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    flex: 1;
    margin-top: 10px;
    background-color: transparent;
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
}