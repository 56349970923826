.Calendar-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    width: 100%;
    margin-top: 20px;
}

.Calendar-TopDayRow {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.Calendar-TopDay {
    display: flex;
    width: calc(100vw / 7);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
}

.Calendar-Body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex: 1;
    position: relative;
}

.Calendar-InnerBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    position: absolute;
}

.Calendar-Month {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}

.Calendar-MonthTitle {
    color: rgba(255, 255, 255, 0.7);
    font-size: 20px;
    padding-left: 15px;
    font-weight: bold;
    padding-bottom: 10px;
}

.Calendar-MonthRow {
    display: flex;;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}

.Calendar-Day {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100vw / 7);
    height: 60px;
    flex-shrink: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.Calendar-DayTitle {
    color: rgba(255, 255, 255, 0.6);
}

.Calendar-Disabled {
    color: rgba(255, 255, 255, 0.3);
}

.Calendar-Selected {
    background: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.Calendar-Spacer {
    display: flex;
    height: 60px;
    flex-shrink: 0;
}