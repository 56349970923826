.CustomService-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    width: 95%;
    height: 280px;
    background-color: rgb(34, 34, 34);
    margin-bottom: 20px;
    position: relative;
    border-radius: 10px;
}

.CustomService-Image {
    width: 100%;
    height: 280px;
    border-radius: 10px;
    object-fit: cover;
}

.CustomService-InfoContainer {
    width: 70%;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
}

.CustomService-PriceContainer {
    width: 30%;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    display: flex;
}

.CustomService-RowContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 80px;
    width: 100%;
    position: absolute;
    bottom: 0px;
}

.CustomService-Name {
    color: #fff;
    margin-block-start: 0px;
    margin-block-end: 0px;
    padding-left: 15px;
    text-shadow: 1px 1px #000;
}

.CustomService-Duration {
    color: rgba(255, 255, 255, 1);
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-top: 5px;
    text-shadow: 1px 1px #000;
    padding-left: 15px;
}

.CustomService-Price {
    padding-right: 15px;
    color: rgba(255, 255, 255, 1);
    text-shadow: 1px 1px #000;
}

.CustomService-ClientName {
    position: absolute;
    top: 5px;
    left: 15px;
    color: #fff;
    text-shadow: 1px 1px #000;
}