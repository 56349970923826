.SIM-Forgot {
    width: 90%;
    text-align: right;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin-top: 15px;
}

.SIM-OrRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 30px;
    margin-top: 20px;
}

.SIM-Or {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
}

.SIM-OrLine {
    width: 40%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.5);
}

.SIM-Button {
    width: 95%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    height: 45px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    border: none;
}