.NB-BadgeContainer {
    position: absolute;
    width: 50px;
    height: 22px;
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    border-radius: 11px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    right: 5px;
    top: 15px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    z-index: 1;
}

.NB-Avatar {
    top: -2px;
    left: -30px;
}

.NB-BadgeIcon {
    color: rgba(255, 255, 255, 1);
    transform-origin: top;
    position: absolute;
    animation-name: tilt;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    left: 5px;
}

.NB-Badge {
    color: rgba(255, 255, 255, 1);
    margin-left: 15px;
    font-weight: bold;
}

@keyframes slidein {
    from {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

@keyframes slideout {
    from {
        margin-left: 0%;
    }
    
    100% {
        margin-left: 100%;
    }
}

@keyframes tilt {
    from {
        transform: rotateZ(0deg);
    }
    10% {
        transform: rotateZ(30deg);
    }
    20% {
        transform: rotateZ(-30deg)
    }
    30% {
        transform: rotateZ(20deg);
    }
    40% {
        transform: rotateZ(-20deg)
    }
    50% {
        transform: rotateZ(10deg);
    }
    60% {
        transform: rotateZ(-10deg)
    }
    70% {
        transform: rotateZ(5deg);
    }
    80% {
        transform: rotateZ(-5deg)
    }
    100% {
        transform: rotateZ(0deg)
    }
}