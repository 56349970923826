.PO-Overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
}

.PO-Curve {
    border-radius: 10px;
}

.PO-Icon {
    color: #D735AF;
    font-size: 80px;
}

.PO-Time {
    color: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 12px;
    font-weight: bold;
}

.PO-Back {
    font-size: 35px;
    color: #fff;
    position: absolute;
    top: 8px;
    left: 7px;
}