.Bullet-Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
}

.Bullet-Circle {
    display: flex;
    width: 14px;
    height: 14px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 7px;
    flex-shrink: 0;
}

.Bullet-Label {
    color: rgba(255, 255, 255, 0.9);
    margin-left: 15px;
    margin-block-start: 0px;
    margin-block-end: 0px;
}