.N2-Container {
    width: 90%;
    margin-bottom: 8px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.N2-IconContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50px;
    height: 100%;
}

.N2-Content {
    display: flex;
    flex: 1;
    height: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding-bottom: 5px;
}

.N2-Stamp {
    position: absolute;
    top: 3px;
    right: 5px;
    font-size: 8px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: bold;
}

.N2-Title {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: #fff;
}

.N2-Desc {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    font-weight: bold;
}