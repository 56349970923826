.TR-Container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
}

.TR-Time {
    margin-block-end: 0px;
    margin-block-start: 0px;
    color: #fff;
}