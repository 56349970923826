.PriceButton-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 95%;
    flex: 1;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.8);
    color: rgba(255, 255, 255, 0.8);
    transition: all 100ms;
}

.PriceButton-Focus {
    border-color: #fff !important;
    color: #fff;
    font-weight: bold;
}

.PriceButton-Disabled {
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.2);
}