.ILM-Container {
    position: relative;
    margin-block-end: 0px;
    margin-block-start: 0px;
}

.ILM-Image {
    object-fit: cover;
}

.ILM-Overlay {
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.6);
    left: 0;
    right: 0;
    bottom: 0;
    top: -1px;
}

.ILM-OverlayInner {
    position: relative;
    width: 100%;
    height: 100%;
}

.ILM-Icon {
    color: #fff;
    font-size: 25px;
    position: absolute;
    right: 5px;
    bottom: 5px;
}