.AR-Container {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 15px;
}

.AR-Title {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: rgba(255, 255, 255, 0.8);
    margin-left: 10px;
    margin-bottom: 10px;
}

.AR-Row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex: 1;
    overflow-x: auto;
}