.AP-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex: 1;
    position: absolute;
}

.AP-TopContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    height: 110px;
}

.AP-DetailContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    height: 100%;
}

.AP-Name {
    color: #fff;
    margin-left: 10px;
    margin-block-start: 0px;
    margin-block-end: 0px;
}


.AP-RowContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
}

.AP-SubTitle {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    font-weight: bold;
}

.AP-Sep {
    background-color: rgba(255, 255, 255, 0.6);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
}

.AP-Dist {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    margin-left: 10px;
}

.AP-Distance {
    color: rgba(255, 255, 255, 0.6);
    margin-left: 10px;
    font-size: 14px;
}