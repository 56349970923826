.InfoRow-Row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    flex-shrink: 0;
}

.InfoRow-Sep {
    width: 8px;
    height: 8px;
    flex-shrink: 0;
    display: flex;
    border-radius: 4px;
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    margin-left: 10px;
    margin-right: 10px;

}

.InfoRow-Info {
    color: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}


.InfoRow-Call {
    text-decoration: none;
}