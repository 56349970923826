.ArtistSettingsModal-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    width: 100%;
}

.ArtistSettingsModal-Title {
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    margin-block-end: 0px;
    margin-block-start: 0px;
    width: 90%;
    margin-bottom: 10px;
    margin-top: 10px;
}

.ArtistSettingsModal-SelectWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    width: 80%;
}

.ArtistSettingsModal-SelectLabel {
    color: rgba(255, 255, 255, 0.8);
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-bottom: 10px;
    margin-left: 5px;
}

.ArtistSettingsModal-NoShow {
    background: none;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    font-size: 16px;
    transition: all 0.3s;
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
    text-align: center;
}

.ArtistSettingsModal-NoShow:focus {
    border: 2px solid #FF3466;
    outline: none;
    border-radius: 2px;
}