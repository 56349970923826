.CC-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 900px;
    height: 900px;
}

.CC-Body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
}

.CC-TimeRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.CC-OuterClock {
    position: relative;
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
}

.CC-Clock {
    background: linear-gradient(-20deg, #000 15%, #1D1D1F);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: visible;
    flex-shrink: 0;
    top: 5px;
    left: 5px;
    z-index: 1;
}

.CC-NumberSelected {
    color: #fff !important;
    font-weight: bold;
}

.CC-Number1 {
    position: absolute;
    right: 27%;
    color: rgba(255, 255, 255, 0.5);
    top: 11%;
    margin-block-start: 0px;
    margin-block-end: 0px;
    user-select: none;
    transition: all 0.2s;
}

.CC-Number2 {
    position: absolute;
    right: 13%;
    color: rgba(255, 255, 255, 0.5);
    top: 24%;
    margin-block-start: 0px;
    margin-block-end: 0px;
    user-select: none;
    transition: all 0.2s;
}

.CC-Number3 {
    position: absolute;
    right: 20px;
    color: rgba(255, 255, 255, 0.5);
    top: 45.5%;
    margin-block-start: 0px;
    margin-block-end: 0px;
    user-select: none;
    transition: all 0.2s;
}

.CC-Number4 {
    position: absolute;
    right: 12%;
    color: rgba(255, 255, 255, 0.5);
    top: 66%;
    margin-block-start: 0px;
    margin-block-end: 0px;
    user-select: none;
    transition: all 0.2s;
}

.CC-Number5 {
    position: absolute;
    right: 27%;
    color: rgba(255, 255, 255, 0.5);
    top: 80%;
    margin-block-start: 0px;
    margin-block-end: 0px;
    user-select: none;
    transition: all 0.2s;
}

.CC-Number6 {
    position: absolute;
    color: rgba(255, 255, 255, 0.5);
    bottom: 16px;
    left: 48%;
    margin-block-start: 0px;
    margin-block-end: 0px;
    user-select: none;
    transition: all 0.2s;
}

.CC-Number7 {
    position: absolute;
    top: 80%;
    color: rgba(255, 255, 255, 0.5);
    left: 27%;
    margin-block-start: 0px;
    margin-block-end: 0px;
    user-select: none;
    transition: all 0.2s;
}


.CC-Number8 {
    position: absolute;
    color: rgba(255, 255, 255, 0.5);
    left: 12%;
    top: 66%;
    margin-block-start: 0px;
    margin-block-end: 0px;
    user-select: none;
    transition: all 0.2s;
}


.CC-Number9 {
    position: absolute;
    left: 20px;
    color: rgba(255, 255, 255, 0.5);
    top: 45.5%;
    margin-block-start: 0px;
    margin-block-end: 0px;
    user-select: none;
    transition: all 0.2s;
}

.CC-Number10 {
    position: absolute;
    color: rgba(255, 255, 255, 0.5);
    left: 12%;
    top: 25.5%;
    margin-block-start: 0px;
    margin-block-end: 0px;
    user-select: none;
    transition: all 0.2s;
}


.CC-Number11 {
    position: absolute;
    color: rgba(255, 255, 255, 0.5);
    left: 25.1%;
    top: 12%;
    margin-block-start: 0px;
    margin-block-end: 0px;
    user-select: none;
    transition: all 0.2s;
}

.CC-Number12 {
    position: absolute;
    top: 16px;
    left: 46%;
    color: rgba(255, 255, 255, 0.5);
    margin-block-start: 0px;
    margin-block-end: 0px;
    user-select: none;
    transition: all 0.2s;
}

.CC-1Tick {
    position: absolute;
    top: 0.3%;
    right: 44.5%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(6deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-2Tick {
    position: absolute;
    top: 1%;
    right: 39%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(12deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-3Tick {
    position: absolute;
    top: 2.3%;
    right: 34.2%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(18deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-4Tick {
    position: absolute;
    top: 4%;
    right: 29.5%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(24deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-5Tick {
    position: absolute;
    top: 6.8%;
    right: 24%;
    width: 5px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    transform-origin: top;
    transform: rotateZ(30deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-6Tick {
    position: absolute;
    top: 9.3%;
    right: 20.5%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(36deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-7Tick {
    position: absolute;
    top: 12.5%;
    right: 16.2%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(42deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-8Tick {
    position: absolute;
    top: 16.5%;
    right: 12.2%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(48deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-9Tick {
    position: absolute;
    top: 20.6%;
    right: 9%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(54deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-10Tick {
    position: absolute;
    top: 25%;
    width: 5px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    right: 6.2%;
    transform-origin: top;
    transform: rotateZ(60deg);
    user-select: none;
}

.CC-11Tick {
    position: absolute;
    top: 29.5%;
    right: 4%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(66deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-12Tick {
    position: absolute;
    top: 34.5%;
    right: 2%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(72deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-13Tick {
    position: absolute;
    top: 39.5%;
    right: 0.5%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(78deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-14Tick {
    position: absolute;
    top: 45%;
    right: 0%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(84deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-15Tick {
    position: absolute;
    right: 1.5%;
    width: 5px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    top: 47.5%;
    transform: rotateZ(90deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-16Tick {
    position: absolute;
    top: 55%;
    right: 0%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(96deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-17Tick {
    position: absolute;
    top: 60.5%;
    right: 0.5%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(102deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-18Tick {
    position: absolute;
    top: 65.5%;
    right: 2.2%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(108deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-19Tick {
    position: absolute;
    top: 70.5%;
    right: 4%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(114deg);
    user-select: none;
    transition: all 0.2s;
}


.CC-20Tick {
    position: absolute;
    right: 8%;
    width: 5px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    top: 71.5%;
    transform: rotateZ(120deg);
    user-select: none;
}

.CC-21Tick {
    position: absolute;
    top: 79.5%;
    right: 9%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(126deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-22Tick {
    position: absolute;
    top: 83.5%;
    right: 12.5%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(132deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-23Tick {
    position: absolute;
    top: 87%;
    right: 16%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(138deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-24Tick {
    position: absolute;
    top: 90.5%;
    right: 20.1%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(144deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-25Tick {
    position: absolute;
    right: 25%;
    width: 5px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    top: 88.8%;
    transform: rotateZ(150deg);
    user-select: none;
}

.CC-26Tick {
    position: absolute;
    top: 95.8%;
    right: 29.3%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(156deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-27Tick {
    position: absolute;
    top: 97.5%;
    right: 34%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(162deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-28Tick {
    position: absolute;
    top: 98.8%;
    right: 39%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(168deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-29Tick {
    position: absolute;
    top: 99.8%;
    right: 44.5%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(174deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-30Tick {
    position: absolute;
    left: 49.3%;
    width: 5px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    bottom: 0px;
    user-select: none;
}

.CC-31Tick {
    position: absolute;
    top: 99.8%;
    right: 55%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(186deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-32Tick {
    position: absolute;
    top: 98.8%;
    right: 60%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(192deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-33Tick {
    position: absolute;
    top: 97.8%;
    right: 65.3%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(198deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-34Tick {
    position: absolute;
    top: 96%;
    right: 70%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(204deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-35Tick {
    position: absolute;
    left: 25.3%;
    width: 5px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    top: 88.9%;
    transform: rotateZ(210deg);
    user-select: none;
}

.CC-36Tick {
    position: absolute;
    top: 90.4%;
    right: 79%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(216deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-37Tick {
    position: absolute;
    top: 87.4%;
    right: 83%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(222deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-38Tick {
    position: absolute;
    top: 83.6%;
    right: 86.8%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(228deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-39Tick {
    position: absolute;
    top: 79.3%;
    right: 90.2%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(234deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-40Tick {
    position: absolute;
    left: 8.1%;
    top: 71.1%;
    width: 5px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    transform: rotateZ(240deg);
    user-select: none;
}

.CC-41Tick {
    position: absolute;
    top: 70.3%;
    right: 95.2%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(246deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-42Tick {
    position: absolute;
    top: 65.3%;
    right: 97.4%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(252deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-43Tick {
    position: absolute;
    top: 60.3%;
    right: 98.6%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(258deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-44Tick {
    position: absolute;
    top: 55.3%;
    right: 99.6%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(264deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-45Tick {
    position: absolute;
    left: 5px;
    width: 5px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    top: 47.5%;
    transform: rotateZ(90deg);
    user-select: none;
}

.CC-46Tick {
    position: absolute;
    top: 44.6%;
    right: 99.6%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(-84deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-47Tick {
    position: absolute;
    top: 39.8%;
    right: 98.6%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(-78deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-48Tick {
    position: absolute;
    top: 34.5%;
    right: 97.2%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(-72deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-49Tick {
    position: absolute;
    top: 29.5%;
    right: 95.2%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(-66deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-50Tick {
    position: absolute;
    left: 8.2%;
    top: 24%;
    width: 5px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    transform: rotateZ(300deg);
    user-select: none;
}

.CC-51Tick {
    position: absolute;
    top: 20.5%;
    right: 90.2%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(-54deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-52Tick {
    position: absolute;
    top: 16.3%;
    right: 86.8%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(-48deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-53Tick {
    position: absolute;
    top: 12.8%;
    right: 83%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(-42deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-54Tick {
    position: absolute;
    top: 9%;
    right: 79%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(-36deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-55Tick {
    position: absolute;
    left: 25.5%;
    top: 6.7%;
    width: 5px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    transform: rotateZ(330deg);
    user-select: none;
}

.CC-56Tick {
    position: absolute;
    top: 4.3%;
    right: 69.9%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(-24deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-57Tick {
    position: absolute;
    top: 2.3%;
    right: 65%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(-18deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-58Tick {
    position: absolute;
    top: 1.1%;
    right: 60%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(-12deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-59Tick {
    position: absolute;
    top: 0.1%;
    right: 55%;
    width: 2.5px;
    height: 10px;
    background-color: #868686;
    transform-origin: top;
    transform: rotateZ(-6deg);
    user-select: none;
    transition: all 0.2s;
}

.CC-60Tick {
    position: absolute;
    top: 0%;
    width: 5px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    left: 49.2%;
    user-select: none;
}


.CC-BigHand {
    width: 15px;
    height: 40%;
    background-color: #868686;
    bottom: 50%;
    transform: rotateZ(0deg);
    position: absolute;
    transform-origin: bottom;
    transition: transform 0.1s;
    transition: background-color 0.2s;
    cursor: move;
    user-select: none;
    z-index: 2;
}

.CC-HandContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

.CC-BigHandPoint {
    width: 11px;
    height: 11px;
    left: 2px;
    position: absolute;
    background-color: #868686;
    transform: rotateZ(45deg);
    top: -6px;
    transition: all 0.2s;
}

.CC-ShortHand {
    width: 14px;
    height: 25%;
    background-color: #505050;
    bottom: 50%;
    transform: rotateZ(30deg);
    position: absolute;
    transform-origin: bottom;
    cursor: move;
    transition: transform 0.1s;
    transition: background-color 0.2s;
    user-select: none;
    z-index: 3;
}

.CC-ShortHandPoint {
    width: 10px;
    height: 10px;
    left: 2px;
    position: absolute;
    background-color: #505050;
    transform: rotateZ(45deg);
    top: -5px;
    transition: all 0.2s;
}

.CC-HandSelected {
    background-color: rgba(255, 255, 255, 1);
}

.CC-MiddleCircleOutline {
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    width: 26px;
    height: 26px;
    position: relative;
    border-radius: 50%;
    z-index: 4;
}

.CC-MiddleCircle {
    position: absolute;
    width: 20px;
    height: 20px;
    background: linear-gradient(-20deg, #000 15%, #1D1D1F);
    border-radius: 50%;
    top: 3px;
    left: 3px;
    z-index: 4;
    user-select: none;
}

.CC-TimeRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 300px;
}

.CC-Time {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: var(--darkPrimary1);
    margin-bottom: 20px;
    text-align: center;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    user-select: none;
    padding-left: 4px;
    padding-right: 4px;
}

.CC-Time:hover {
    background-color: #E2ECF3;
}

.CC-DarkTime {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: var(--lightPrimary2);
    margin-bottom: 20px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    border-bottom: 3px solid transparent;
    padding-left: 4px;
    padding-right: 4px;
}

.CC-DarkTimeSelected {
    border-bottom: 3px solid rgba(121,177,43, 1);
}

.CC-TimeSelected {
    background-color: #2C3E50;
    color: rgba(255, 255, 255, 0.5);
}

.CC-TimeSelected:hover {
    color: var(--lightPrimary2) !important;
}

.CC-DarkTime:hover {
    border-bottom: 3px solid rgba(121,177,43, 0.8);
}
