.PortfolioImage-Container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.PortfolioImage-SelectedContainer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.PortfolioImage-Check {
    font-size: 25px;
    color: #D735AF;
    margin-right: 10px;
    margin-bottom: 10px;
}