.MS-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    border: 2px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-shrink: 0;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
}

.MS-CurrentMonth {
    margin-block-end: 0px;
    margin-block-start: 0px;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 5px;
}


