.BrandButton-Button {
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 45px;
    width: 75%;
    border: none;
    transition: all 0.3s;
    flex-shrink: 0;
}

.BrandButton-Disable {
    background-image: linear-gradient(to right, rgb(126, 31, 102), rgb(170, 36, 70), rgb(153, 31, 31));
    color: rgba(255, 255,  255, 0.5)
}

.BrandButton-Row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 75%;
    height: 45px;
}