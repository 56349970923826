.TS-Row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
}

.TS-Bullet {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.7);
}