.StripeId-FlexButtonInnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.StripeId-DateInput {
    background-color: transparent;
    border: none;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    padding-left: 25px;
}

.StripeId-AbsoluteLabel {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.StripeId-ImagePicker {
    display: none;
}

.StripeId-Image {
    width: 99%;
    height: 99%;
    object-fit: cover;
    border-radius: 7px;
}