.Pill-Outer {
    position: relative;
    background-image: linear-gradient(to right, rgb(126, 31, 102), rgb(170, 36, 70), rgb(153, 31, 31));
    width: 150px;
    height: 38px;
    margin-right: 15px;
    flex-shrink: 0;
}

.Pill-OuterSelected {
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
}

.Pill-Inner {
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 1;
    width: 146px;
    height: 34px;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    transition: all 0.3s;
}

.Pill-Selected {
    background-color: transparent;
    color: #fff;
}