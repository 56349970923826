.PayModal-Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
}

.PayModal-TipOptionsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 95%;
    height: 70px;
}

.PayModal-TipButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    transition: all 0.3s;
}

.PayModal-TipButtonSelected {
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    border-width: 0px;
    flex: 1.1;
}

.PayModal-TipButtonPercent {
    color: rgba(255, 255, 255, 0.3);
    font-size: 18px;
    font-weight: bold;
}

.PayModal-TipButtonLabelSelected {
    color: #fff !important;
}

.PayModal-TipButtonPrice {
    color: rgba(255, 255, 255, 0.2);
    font-size: 16px;
    font-weight: bold;
}

.PayModal-TipButtonMargin {
    margin-right: 5px;
}

.PayModal-SubTitle {
    width: 95%;
    color: rgba(255, 255, 255, 0.8);
    text-align: left;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-bottom: 10px;
    padding-left: 20px;
}

.PayModal-SubRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 30px;
}

.PayModal-SubLabel {
    color: rgba(255, 255, 255, 0.8);
}

.PayModal-SubValue {
    color: #fff;
    font-weight: bold;
}

.PayModal-CenterContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex: 1;
    padding-top: 50%;
}

.PayModal-Success {
    color: #D735AF;
    font-size: 90px;
}