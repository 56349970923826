.Charge-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    position: relative;
    width: 95%;
    height: 130px;
    flex-shrink: 0;
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}

.Charge-Amount {
    position: absolute;
    top: 10px;
    left: 15px;
    color: rgba(255, 255, 255, 0.8);
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.Charge-Created {
    position: absolute;
    bottom: 10px;
    left: 15px;
    color: rgba(255, 255, 255, 0.5);
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.Charge-Desc {
    position: absolute;
    top: 40px;
    left: 15px;
    color: rgba(255, 255, 255, 0.5);
    margin-block-start: 0px;
    margin-block-end: 0px;
}