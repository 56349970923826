.ScheduleModal-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex: 1;
}

.ScheduleModal-Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    margin-block-start: 0px;
    margin-block-end: 0px;
    height: 20px;
    color: rgba(255, 255, 255, 0.4);
    margin-bottom: 5px;
}

.ScheduleModal-Today {
    color: #fff;
}