.DurationControl-Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}

.DurationControl-Container {
    background: none;
    border: none;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.3);
    font-weight: bold;
    font-size: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    width: 80%;
    transition: all 0.3s;
}

.DurationControl-Container:focus {
    outline: none;
    border-color: #fff;
    color: #fff;
}

.DurationControl-Label {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.3);
    width: 80%;
    margin-bottom: 15px;
    font-weight: bold;
    transition: all 0.3s;
    position: absolute;
    bottom: 40px;
}

.DurationControl-Container:focus + .DurationControl-Label {
    color: #fff;
}