.CustomServiceModal-EmptyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1;
}

.CustomServiceModal-EmptyTitle {
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    width: 80%;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.CustomServiceModal-Description {
    width: 85%;
    text-align: center;
    color: rgba(255, 255, 255, 0.5)
}