.SetSchedule-RepeatValueContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 50px;
}

.SetSchedule-BreakLabel {
    color: #fff;
    font-size: 18px;
}