.SIF-Forgot {
    width: 90%;
    text-align: right;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin-top: 15px;
}

.SIF-OrRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 30px;
    margin-top: 20px;
}

.SIF-Or {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
}

.SIF-OrLine {
    width: 40%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.5);
}

.SIF-Bottom {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.SIF-Button {
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 45px;
    width: 75%;
    border: none;
    transition: all 0.3s;
    flex-shrink: 0;
}

.SIF-Disable {
    background-image: linear-gradient(to right, rgb(126, 31, 102), rgb(170, 36, 70), rgb(153, 31, 31));
    color: rgba(255, 255,  255, 0.5)
}
