.NG-Container {
    width: 90%;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    transition: all 0.5s;
    margin-bottom: 5px;
    padding: 15px;
}


.NG-Title {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: #fff;
    margin-left: 5px;
}