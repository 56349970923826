.ArtistChargeDetail-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex: 1;
}

.ArtistChargeDetail-Description {
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
    font-weight: bold;
    margin-block-end: 0px;
    margin-block-start: 0px;
    margin-bottom: 20px;
}

.ArtistChargeDetail-Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-top: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.ArtistChargeDetail-Label {
    color:rgba(255, 255, 255, 0.7);
    margin-block-end: 0px;
    margin-block-start: 0px;
}

.ArtistChargeDetail-Value {
    color: #fff;
    margin-block-end: 0px;
    margin-block-start: 0px;
}

.ArtistChargeDetail-Date {
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
    font-weight: bold;
    margin-block-end: 0px;
    margin-block-start: 0px;
    margin-top: 25px;
}