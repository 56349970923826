
.TabButtonRow-Container {
    width: 95%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-top: 15px;
    margin-bottom: 15px;
}
