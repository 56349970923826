.UB-Bio {
    background: none;
    flex: 1;
    width: 96%;
    border: none;
    outline: none;
    font-size: 18px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    overflow-y: auto;
}