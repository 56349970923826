.NoShowModal-Label {
    text-align: center;
    color: rgba(255, 255, 255, 0.8)
}

.NoShowModal-Info {
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    width: 85%;
}

.NoShowModal-NoShowFee {
    color: #fff;
    text-align: center;
    margin-block-end: 0px;
    margin-block-start: 0px;
}

.NoShowModal-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex: 1;
}
