.BTB-TabContainer {
    position: relative;
    display: flex;
    transition: all 0.3s;
}

.BTB-ContainerSelected {
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
}

.BTB-Tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: rgba(255, 255, 255, 0.3);
    transition: all 0.3s;
}

.BTB-TabSelected {
    background-image: linear-gradient(360deg, #000, rgb(48, 48, 48));
    color: #fff;
}

.BTB-Label {
    margin-block-start: 0px;
    margin-block-end: 0px;
}
