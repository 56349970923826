.TabButton-Tab {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border-radius: 5px;
    transition: all 0.3s;
    color: rgba(255, 255, 255, 0.6);
    border: 2px solid rgba(255, 255, 255, 0.1);
}

.TabButton-Selected {
    color: #fff !important;
    border: 2px solid #fff !important;
}