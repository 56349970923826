.GradientBackground-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(360deg, #000, rgb(48, 48, 48));
    overflow: hidden;
}