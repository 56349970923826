.BottomUpModal-Overlay {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: absolute;
    transition: all 0.2s;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.8);
}

.BottomUpModal-Open {
    z-index: 1;
}

.BottomUpModal-Title {
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    width: 85%;
}

.BottomUpModal-Body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    background: linear-gradient(190deg, #000 40%, #1D1D1F);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    position: absolute;
    transition: all 0.5s;
    background-size: cover;
    background-attachment: fixed;
    object-fit: cover;
}

.BottomUpModal-TitleContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.BottomUpModal-DragBar {
    position: absolute;
    top: 10px;
    width: 140px;
    height: 3px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.8);
}