.SC-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SC-Circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border: 4px solid;
    border-radius: 50%;
    color: #fff;
}

.SC-Label {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 5px
}