.Stat-Container {
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
}



.Stat-Title {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-top: 15px;
    margin-bottom: 10px;
    color: #fff;
}

.Stat-Selected {
    background: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Stat-Row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25px;
}

.Stat-Left {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    height: 100%;
}

.Stat-Right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
}

.Stat-Label {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: rgba(255, 255, 255, 0.6);
}

.Stat-Value {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: rgba(255, 255, 255, 0.9);
}