.PriceLabel-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    border: 2px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.PriceLabel-Label {
    background: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);    
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 70%;
    text-align: center;
    margin-block-start: 0px;
    margin-block-end: 0px;
}