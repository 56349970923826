.Body-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex: 1;
    transition: all 0.3s;
}

.Body-Relative {
    position: relative;
}

.Body-Center {
    justify-content: center;
}

.Body-Absolute {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}