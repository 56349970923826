.Loader-Container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    z-index: -1;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .Loader-Show {
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .Loader-Message {
    font-size: 25px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-weight: bold;
    margin-top: 30px;
  }

  .Loader-Success {
    font-size: 200px;
    color: #FF3466;
  }

  .Loader-Error {
    font-size: 200px;
    color: #FF3466;
  }