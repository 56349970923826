.PriceControl-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex: 1;
}

.PriceControl-PriceRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 88%;
    height: 40px;
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    color: #fff;
}

.PriceControl-Body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 90%;
    flex: 1;
}

.PriceControl-Price {
    margin-right: 15px;
}