.ST-Title {
    color: rgba(255, 255, 255, 1);
    text-align: center;
    margin-block-end: 0px;
    margin-block-start: 0px;
    width: 90%;
    margin-bottom: 10px;
    margin-top: 10px;
}

.ST-Error {
    color: salmon;
}