

.BB2-Outer {
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    height: 45px;
    width: 95%;
    border: none;
    flex-shrink: 0;
    position: relative;
}

.BB2-Inner {
    background-color:rgb(27, 27, 27);
    position: absolute;
    width: calc(100% - 4px);
    height: 41px;
    left: 2px;
    top: 2px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}