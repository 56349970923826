.DurationPicker-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    width: 100%;
}

.DurationPicker-Container2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
}

.DurationPicker-TopRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
}

.DurationPicker-Button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    height: 100%;
    transition: all 0.3s;
}

.DurationPicker-Selected {
    border-color: #fff;
}
