.N-Container {
    width: 95%;
    height: 75px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    transition: all 0.5s;
    margin-bottom: 5px;
}

.N-IconSection {
    height: 100%;
    width: 65px;
    color: #D735AF;
    font-size: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.N-ContentSection {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
}

.N-Title {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: #fff;
}

.N-Desc {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    font-weight: bold;
}

.N-Stamp {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: bold;
}