.FullPhotoModal-Image {
    object-fit: cover;
    width: 100%;
    position: absolute;
}

.FullPhotoModal-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1;
    position: relative;
}

.FullPhotoModal-Right {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.7);
    font-size: 60px;
}

.FullPhotoModal-Left {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.7);
    font-size: 60px;
}