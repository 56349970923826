.TransparentInput-Input {
    width: 90%;
    height: 30px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    background-color: transparent;
    border: none;
    font-weight: bold;
}

.TransparentInput-Input:focus {
    outline: none;
}