

.ArtistAddressModal-Input {
    width: 85%;
    background-color: transparent;
    margin-bottom: 15px;
    font-size: 18px;
    color: #fff;
    padding: 10px;
}

.ArtistAddressModal-Input::placeholder {
    color:rgba(255, 255, 255, 0.7)
}