.AddressPicker-Search {
    width: 90%;
    height: 30px;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    margin-top: 15px;
    color: #fff;
    font-size: 18px;
    padding-left: 15px;
    margin-bottom: 20px;
}

.AddressPicker-Search:focus {
    outline: none;
}

.AddressPicker-Loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1;
}

.AddressPicker-List {
    display: flex;
    width: 100%;
    position: relative;
    flex: 1;
}

.AddressPicker-Address {
    width: 90%;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: rgba(255, 255, 255, 0.9);
    padding-left: 15px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    padding-top: 5px;
}