.ProfileImage-Container {
    position: relative;
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    border-radius: 50%;
    margin-top: 15px;
}

.ProfileImage-Inner {
    object-fit: cover;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
}

  