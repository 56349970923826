.IP-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    position: absolute;
    z-index: 2;
    transition: all 0.3s;
}

.IP-Inner {
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    position: relative;
    border-radius: 8px;
}

.IP-Inner2 {
    background-color:rgb(27, 27, 27);
    position: absolute;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.IP-Message {
    color: rgba(255, 255, 255, 0.9);
    margin-block-end: 0px;
    text-align: center;
    margin-block-start: 0px;
    margin-left: 15px;
}