.TransparentHeader-Container {
    width: 100vw;
    height: 50px;
    background-color: transparent;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.TransparentHeader-Title {
    color: #fff;
    font-size: 20px;
    text-align: center;
    font-family: 'Roboto Bold';
}

.TransparentHeader-Back {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    border: none;
    font-size: 35px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.TransparentHeader-Settings {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    border: none;
    font-size: 35px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.TransparentHeader-LoadContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 0px;
    height: 4px;
    background-color: transparent;
}

.TransparentHeader-LoadPart {
    width: 0%;
    height: 100%;
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
}

.TransparentHeader-MoveLoad {
    animation-name: slidein, slideout;
    animation-duration: 0.7s, 0.7s;
    animation-iteration-count: infinite
}