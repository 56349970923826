.RowCalendar-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.RowCalendar-Darken {
    background-color: rgba(0, 0, 0, 0.7);
}

.RowCalendar-Row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 80px;
    overflow-x: auto;
    padding-bottom: 6px;
}


.RowCalendar-Day {
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    margin-left: 8px;
    flex-shrink: 0;
    width: 80px;
    transition: all 0.3s;
    position: relative;
}

.RowCalendar-Today {
    border-color: #fff;
    border-width: 3px;
}

.RowCalendar-Badge {
    position: absolute;
    top: -5px;
    right: -6px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    box-shadow: 2px 2px 2px #000;
}

.RowCalendar-BadgeTitle {
    padding-bottom: 1px;
}

.RowCalendar-DaySelected {
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
}

.RowCalendar-Number {
    color: #fff;
    margin-block-end: 0px;
    margin-block-start: 0px;
}

.RowCalendar-DayTitle {
    color: rgba(255, 255, 255, 0.5);
    margin-block-end: 0px;
    margin-block-start: 0px;
    transition: all 0.3s;
}

.RowCalendar-TitleSelected {
    color: #fff;
}

.RowCalendar-Right {
    font-size: 30px;
    color: #fff;
}