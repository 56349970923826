.AddService-ImagePicker {
    visibility: hidden;
}

.AddService-ImageHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;;
}

.AddService-ImageIcon {
    font-size: 120px;
    color: rgba(255, 255, 255, 0.5);
}

.AddService-Image {
    object-fit: cover;
    width: 94%;
    height: 33.33%;
    margin-top: 25px;
    border-radius: 10px;
}

.AddService-Select {
    background: none;
    width: 80%;
    border: none;
}

.AddService-Select:focus {
    outline: none;
}