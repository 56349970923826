.Card-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    margin-bottom: 25px;
}

.Card-InnerContainer {
    width: 97%;
    height: 95%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: linear-gradient(360deg, #000, rgb(48, 48, 48));
}

.Card-CardNumberRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    bottom: 40px;
    left: 30px;
    position: absolute;
    bottom: 45px;
}

.Card-Char {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.6);
    margin-left: 3px;
}

.Card-GoldChip {
    width: 60px;
    height: 40px;
    background-color: silver;
    border-radius: 5px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    left: 30px;
    bottom: 100px;
}

.Card-GoldChipSector {
    flex: 1;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.Card-ChipCenterWire {
    border-width: 1px;
    border-color: black;
    height: 100%;
    width: 100%;
    border-radius: 30px;
    border-style: solid;
    display: flex;
}

.Card-SideWire {
    width: 100%; 
    height: 30%;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-color: black;
    border-style: solid;
    display: flex;
    border-right-width: 0px;
    border-left-width: 0px;
}

.Card-Last4 {
    color: rgba(255, 255, 255, 0.8);
    margin-left: 10px;
    padding-bottom: 2px;
}

.Card-Name {
    color: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 30px;
    bottom: 15px;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.Card-ExpRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: 40px;
    left: 30px;
}

.Card-ValidThru {
    color: rgba(255, 255, 255, 0.4);
    width: 50px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-size: 8px;
    font-weight: bold;
}

.Card-Exp {
    color: rgba(255, 255, 255, 0.8);
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.Card-Brand {
    color: rgba(255, 255, 255, 0.8);
    font-size: 50px;
    position: absolute;
    right: 20px;
    bottom: 10px;
}