.SearchBar-Container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100vw;
    height: 60px;
}

.SearchBar-Input {
    width: 85%;
    background-color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    color: #fff;
    caret-color: rgba(255, 255, 255, 0.5);
    padding-left: 15px;
}

.SearchBar-Input::placeholder {
    color: rgba(255,255,255,0.5)
}
