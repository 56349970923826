.PolicyModal-Section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.PolicyModal-Title {
    color: #fff;
    width: 80%;
    text-align: center;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.PolicyModal-Desc {
    color: rgba(255, 255, 255, 0.6);
    width: 85%;
    text-align: center;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
}