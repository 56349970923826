.Rating-Container {
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    flex-shrink: 0;
}

.Rating-Name {
    color: rgba(255, 255, 255, 0.7);
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.Rating-Circle {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    margin-left: 15px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: rgba(255, 255, 255, 0.8);
}

.Rating-Time {
    position: absolute;
    left: 65px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    bottom: 0px;
}