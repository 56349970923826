.ModalSuccess-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex: 1;
    position: relative;
}


.ModalSuccess-Icon {
    color: #D735AF;
    font-size: 90px;
}