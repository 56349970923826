.StatsRow-Row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90vw;
    height: 30px;
    margin-top: 5px;
    flex-shrink: 0;
}

.StatsRow-Stats {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
}

.StatsRow-Icon {
    font-size: 30px;
    color: rgba(255, 255, 255, 0.6)
}

.StatsRow-Selected {
    color: #D735AF !important;
}

.StatsRow-IconLabel {
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
    margin-left: 5px;
    font-weight: bold;
}