.StripeElement,
.StripeElementIdeal {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 100%;
  padding: 10px 14px;
  border-radius: 4px;
  background: transparent;
  width: 90%;
}

.StripeElement--webkit-autofill {
  background-color: transparent;
  background-image: none;
}

.StripeElement--focus,
.StripeElementIdeal--focus {
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal {
  padding: 0;
}

.CM-Search {
    flex: 1;
    margin-right: 5px;
    background-color: transparent;
    height: 40px;
    font-size: 16px;
    border-radius: 2px;
    color: #fff;
    margin-bottom: 10px;
    caret-color: #FF3466;
    transition: all 0.4s;
    border-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1)
} 

.CM-Search:focus {
    border: 2px solid #FF3466;
    outline: none;
    border-radius: 2px;
}

.CM-SearchRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.CM-Back {
    color: #fff;
    border: none;
    font-size: 35px;
    margin-bottom: 5px;
    margin-left: 10px;
}

.CM-Success {
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
}