.App-Container {
  width: 100vw;
  height: 100vh;
}

body > div {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

html, body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}