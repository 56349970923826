.DI-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex: 1;
    padding-bottom: 30px;
}

.DI-Button {
    display: flex;
    flex: 1;
}

.DI-Value {
    color: #fff;
}