.PII-ImageHolder {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.2);
    font-size: 50px;
    object-fit: cover;
}

.PII-ImageContainer {
    position: relative;
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    border-radius: 50%;
    width: 135px;
    height: 135px;
}

.PII-Image {
    width: 125px;
    height: 125px;
    border-radius: 62.5px;
    object-fit: cover;
    position: absolute;
    top: 5px;
    left: 5px;
}