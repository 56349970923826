.A-Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    height: 100px;
    margin-bottom: 10px;
}

.A-Image {
    object-fit: cover;
    width: 90px;
    height: 90px;
    border-radius: 5px;
}

.A-Right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    height: 100%;
}

.A-Name {
    color: #fff;
    margin-left: 10px;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.A-RowContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.A-SubTitle {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
}

.A-Sep {
    background-color: rgba(255, 255, 255, 0.6);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
}

.A-Dist {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    margin-left: 10px;
}