.Portfolio-OuterContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    flex: 1;
    position: relative;
}

.Portfolio-Container {
    width: 100vw;
    top: 5px;
    bottom: 0px;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
    position: absolute;
}

.Portfolio-Inner {
    width: 100vw;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
}

.Portfolio-ImagePicker {
    visibility: hidden;
}

.Portfolio-Footer {
    position: relative;
    height: 50px;
    width: 100vw;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Portfolio-EditButton {
    font-size: 25px;
    color: #fff;
    position: absolute;
    right: 5%;
    top: 60%;
    transform: translateY(-40%);
}

.Portfolio-CancelEdit {
    left: 5% !important;
    transform: rotateZ(180deg) !important;
    top: 30% !important;
}

.Portfolio-CancelEditHide {
    font-size: 35px;
    color: #fff;
    position: absolute;
    left: -8%;
    top: 60%;
    transform: translateY(-40%);
    transition: all 0.3s;
}

.Portfolio-HideImageLabel {
    font-family: 'Roboto Bold';
    transition: all 0.3s;
    color: #fff;
    font-size: 0px;
    margin-top: 10px;
}

.Portfolio-ImageLabel {
    font-size: 18px;
    color: #fff;
    margin-top: 15px;
}