.Option-Container {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}

.Option-IconSquare {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}

.Option-LabelContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    height: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #fff;
    margin-left: 15px;
}

.Option-RightContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.Option-Arrow {
    color: #fff;
    font-size: 25px;
}

.Option-RightLabel {
    color: #fff;
    margin-right: 10px;
}

.Option-Badge {
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-weight: bold;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}