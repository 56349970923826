.DD-Container {
    background-image: linear-gradient(180deg, #000 50%, rgb(48, 48, 48));
    width: 100%;
    height: 100%;
    overflow-x: none;
    overflow-y: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.DD-Header {
    color: #fff;
    text-align: center;
    user-select: none;
}