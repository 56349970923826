.VC-Row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    flex: 1;
}


.VC-Atag {
    width: 95%;
    text-decoration: none;
}