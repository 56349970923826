.ANS-DrawerClose {
    width: 100%;
    background-color: transparent;
    position: absolute;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 40;
}