.AFR-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 160px;
    margin-left: 15px;
    position: relative;
    overflow: visible;
    flex-shrink: 0;
}

.AFR-ImageContainer {
    width: 100%;
    height: 135px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.AFR-Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.AFR-Name {
    color: rgba(255, 255, 255, 0.8);
    margin-block-end: 0px;
    margin-block-start: 0px;
    margin-top: 5px;
    width: 100%;
    text-align: center;
}