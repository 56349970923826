.TimeSlotPicker-NoDayYet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1;
    color: rgba(255, 255, 255, 0.7)
}

.TimeSlotPicker-Slot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 60px;
    flex-shrink: 0;
    border: 1px solid rgba(255, 255, 255, 0.3); 
    border-radius: 5px;
    margin-bottom: 15px;
    color: #fff;
}

.TimeSlotPicker-SlotTitle {
    margin-block-start: 0px;
    margin-block-end: 0px;
}
