.Appointment-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    height: 280px;
    margin-bottom: 15px;
    flex-shrink: 0;
    position: relative;
}

.Appointment-Image {
    width: 100%;
    height: 280px;
    border-radius: 10px;
    object-fit: cover;
}

.Appointment-ClientName {
    position: absolute;
    left: 10px;
    top: -5px;
    color: rgba(255, 255, 255, 1);
    text-shadow: 1px 1px #000;
}

.Appointment-Date {
    position: absolute;
    left: 12px;
    top: 25px;
    color: rgba(255, 255, 255, 1);
    text-shadow: 1px 1px #000;
}

.Appointment-RowContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 80px;
    width: 100%;
    position: absolute;
    bottom: 0px;
}

.Appointment-InfoContainer {
    width: 80%;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
}

.Appointment-Name {
    color: #fff;
    margin-block-start: 0px;
    margin-block-end: 0px;
    padding-left: 15px;
    text-shadow: 1px 1px #000;
}

.Appointment-Duration {
    color: rgba(255, 255, 255, 1);
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-top: 5px;
    text-shadow: 1px 1px #000;
    padding-left: 15px;
}

.Appointment-Duration2 {
    color: rgba(255, 255, 255, 1);
    margin-block-start: 0px;
    margin-block-end: 0px;
    text-shadow: 1px 1px #000;
    padding-left: 15px;
}

.Appointment-PriceContainer {
    width: 30%;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    display: flex;
}

.Appointment-Price {
    padding-right: 15px;
    color: rgba(255, 255, 255, 1);
    text-shadow: 1px 1px #000;
}
