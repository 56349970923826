.ModalRowBack-BackContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    position: relative;
    margin-bottom: 10px;
}

.ModalRowBack-Back {
    color: #fff;
    border: none;
    font-size: 35px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.ModalRowBack-BackTitle {
    margin-block-end: 0px;
    margin-block-start: 0px;
    color: #fff;
}