.FlexRowButton-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 95%;
    flex: 1;
    background-color: transparent;
    border: 3px solid rgba(255, 255, 255, 0.2);
    transition: all 100ms;
    border-radius: 2px;
}

.FRB-Relative {
    position: relative;
}

.FlexRowButton-Focus {
    border-color: #fff !important;
}