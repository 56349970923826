.ModalInput-Input {
    width: 80%;
    margin-left: 14px;
    margin-top: 15px;
    font-size: 16px;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: transparent;
}

.ModalInput-Input::placeholder {
    color: rgba(255, 255, 255, 0.2)
}

.ModalInput-Input:focus {
    border: 2px solid #FF3466;
    outline: none;
    border-radius: 2px;
}