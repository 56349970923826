.I-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
}

.I-SearchHeader {
    justify-content: flex-end;
}


.I-RightHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: auto;
}

.I-SearchIcon {
    font-size: 35px;
    color: #fff;
    margin-right: 20px;
}

.I-Search {
    width: 10%;
    background-color: transparent;
    margin-top: 15px;
    height: 40px;
    font-size: 16px;
    border-radius: 2px;
    color: #fff;
    margin-bottom: 10px;
    caret-color: #FF3466;
    transition: all 0.4s;
    border-color: transparent;
}

.I-SearchOpen {
    flex: 1;
    border-color: rgba(255, 255, 255, 0.1);
}

.I-SearchMessage {
    color: transparent;
    text-align: center;
    width: 70%;
    transition: all 0.4s;
    color: transparent;
    position: absolute;
    top: 50%;
}

.I-SearchMessageVisible {
    color: rgba(255, 255, 255, 0.8);
    top: 20%;
}

.I-Cancel {
    color: rgba(255, 255, 255, 0.8);
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-left: 10px;
    margin-right: 10px;
}

.I-Search:focus {
    border: 2px solid #FF3466;
    outline: none;
    border-radius: 2px;
}

.I-PillRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: transparent;
    overflow-x: auto;
    margin-top: 15px;
}
