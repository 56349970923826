.PortfolioPicker-OuterContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex: 1;
    position: relative;
    margin-bottom: 20px;
}

.PortfolioPicker-Container {
    width: 100%;
    top: 5px;
    bottom: 0px;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
    position: absolute;
}

.PortfolioPicker-Inner {
    width: 100%;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
}