.Address2-Container {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 5px;
}

.Address-Title {
    width: 90%;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-bottom: 5px;
}