.DI-Input {
    background-color: transparent;
    border: none;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    padding-left: 10px;
}

.DI-AbsoluteLabel {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}