.AreYouSure-Overlay {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    position: absolute;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.8);
}

.AreYouSure-Modal {
    width: 320px;
    padding-bottom: 20px;
    display: flex;
    background-color: #1D1D1F;
    transition: all 0.4s;
    border-radius: 10px;
    position: absolute;
    left: 500px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.AreYouSure-ModalOpen {
    left: calc((100vw - 320px)/2)
}

.AreYouSure-Open {
    z-index: 1;
}

.AreYouSure-Title {
    color: rgba(255, 255, 255, 0.9);
    padding-left: 15px;
    width: 85%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
}

.AreYouSure-TitleContainer {
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    width: 320px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.AreYouSure-TypeMessage {
    padding-left: 14px;
    width: 90%;
    padding-top: 15px;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.5);
}

.AreYouSure-ValueToType {
    font-weight: bold;
    margin-left: 5px;
    color: rgba(255, 255, 255, 0.8)
}

.AreYouSure-Input {
    width: 200px;
    margin-left: 14px;
    margin-top: 15px;
    font-size: 15px;
    background-color: rgba(0, 0, 0, 0.4);
    border: none;
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
}

.AreYouSure-Input::placeholder {
    color: rgba(255, 255, 255, 0.3)
}

.AreYouSure-Input:focus {
    outline: none;
}

.AreYouSure-BottomRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.AreYouSure-Delete {
    color: rgba(255, 255, 255, 0.3);
    margin-right: 20px;
    font-size: 18px;
    transition: all 0.3s;
}

.AreYouSure-DeleteEnable {
    background: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}