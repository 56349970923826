.ArtistScheduleModal-VacayLabel {
    background: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.ArtistScheduleModal-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex: 1;
}

.ASM-BreakContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}