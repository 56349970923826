.FAB-Container {
    width: 70px;
    height: 70px;
    position: absolute;
    border-radius: 50%;
    bottom: 40px;
    right: 10px;
    background-image: linear-gradient(to right, #D735AF, #FF3466, #FF3A3A);
    display: flex;
}

.FAB-InnerRelative {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 50%;
}

.FAB-InnerAbsolute {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    background-color:rgb(27, 27, 27);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 35px;
}