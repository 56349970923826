
.ArtistHome-Row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    margin-top: 35px;
    margin-bottom: 15px;
}

.ArtistHome-Add {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 35px;
    color: #fff
}

.ArtistHome-Edit {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 35px;
    color: #fff
}

.ArtistHome-ImagePicker {
    visibility: hidden;
}