.ImageBackground-Container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-size: cover;
    background-attachment: fixed;
    object-fit: cover;
    transition: all 0.3s;
    overflow: hidden;
}

.ImageBackground-InnerContainer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}

.ImageBackground-Darken {
    background: linear-gradient(rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0.5), #000 70%);
}