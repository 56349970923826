.AS-Search {
    flex: 1;
    margin-right: 5px;
    background-color: transparent;
    height: 40px;
    font-size: 16px;
    border-radius: 2px;
    color: #fff;
    margin-bottom: 10px;
    caret-color: #FF3466;
    transition: all 0.4s;
    border-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1)
} 

.AS-Search:focus {
    border: 2px solid #FF3466;
    outline: none;
    border-radius: 2px;
}

.AS-SearchRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.AS-Back {
    color: #fff;
    border: none;
    font-size: 35px;
    margin-bottom: 5px;
    margin-left: 10px;
}