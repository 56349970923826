.BI-InputContainer {
    width: 90%;
    height: 70px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.BI-Input {
    width: 100%;
    height: 35px;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    z-index: 1;
    border: 1px solid rgba(255, 255, 255, 0.7)
}

.BI-InputPlaceholder {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
    position: absolute;
    bottom: 12px;
    left: 10px;
    z-index: 2;
    transition: all 0.5s;
}

.BI-Label {
    bottom: 55px;
    left: -5px;
    font-size: 17px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.8);
}

.BI-ActiveLabel {
    color: #FF3466;
}

.BI-Input:focus {
    outline: none;
    border-color: #FF3466;
    border-width: 2px;
}

