.MapMarker-Marker {
    width: 200px;
    height: 60px;
    background-color: #fff;
    position: relative;
    border-top-left-radius: 1px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.MapMarker-SalonName {
    margin-block-end: 0px;
    margin-block-start: 0px;
    font-size: 15px;
    color: black;
    padding-left: 5px;
    padding-top: 2px;
}

.MapMarker-Address {
    padding-left: 5px;
}
