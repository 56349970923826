.Balance-BalanceContainer {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Balance-BalanceColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.Balance-Amount {
    color: rgba(255, 255, 255, 0.8);
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.Balance-Label {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: rgba(255, 255, 255, 0.4);
    font-weight: bold;
    font-size: 12px;
    margin-top: 3px;
}

.Balance-Empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1;
}

.Balance-EmptyTitle {
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    border: 2px solid rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 5px;
}