.Message-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin-top: 10px;
    background-color: transparent;
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
    font-weight: bold;
}

.Message-ErrorContainer {
    border: 3px solid rgba(250, 128, 114, 0.4);
    color: rgba(250, 128, 114, 0.7);
}

.ErrorMessage-message {
    width: 90%;
    text-align: center;
}